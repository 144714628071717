:root {
    --header-height: 3rem;
    --nav-width: 94px;

    --first-color: #00385E;
    --first-color-alt: #0472bb;
    --white-color: white;
    --light-gray-color: #e7e7e7; 
    --time: 0.5s;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}

button {
    border: none;
}


.img-min {
    height: 78px;
    width: 78px;
    padding: 0;
    margin: 0;
    margin-left: 0.5rem;
    transition: var(--time);
}

.img-max {
    height: 194px;
    width: 194px;
    margin-left: 1.05rem;
    transition: var(--time);
}

.show-title {
    display: inline-block;
    margin-left: 1rem;
    transition: var(--time);
}

.hide-title {
    display: none;
    transition: var(--time);
}


main {
    position: relative;
    margin: var(--header-height) 0 0 0;
    padding: 0.1rem;
    font-size: 1rem;
    transition: var(--time);
}

header {
    position: fixed;
    top: 0;
    left: 0;
    height: var(--header-height);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--light-gray-color);
    padding: 0 1rem;
    margin-left: 1rem;
    transition: var(--time);
}

.header-toggle {
    font-size: 1.25rem;
    cursor: pointer;
    color: var(--first-color);
}

.sidebar {
    position: fixed;
    top: 0;
    left: -30%;
    height: 100vh;
    width: var(--nav-width);
    background-color: var(--first-color);
    padding-top: 1rem;
    transition: var(--time);
}

.nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    height: 100%;
}


.nav-logo,
.nav-link {
    display: grid;
    grid-template-columns: max-content max-content;
    column-gap: 0.8rem;
    padding: 0.1rem 0 0.1rem 1.25rem;
}


.nav-logo {
    margin-bottom: 2rem;
    margin-top: 0;
    padding-left: 0;
}

.nav-logo-icon {
    cursor: pointer;
    margin-left: 0.8rem;
}

.nav-logo-name {
    padding-top: 1rem;
    font-size: 1.3rem;
    font-weight: bold;
    color: var(--white-color);
}





.nav-link-icon {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.nav-link-name {
    font-size: 1rem;
    font-weight: bold;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}



.nav-link {
    position: relative;
    color: var(--first-color-alt);
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    transition: var(--time);
}

.nav-footer {
    display: grid;
    grid-template-columns: max-content max-content;
    column-gap: 0.8rem;
    padding: 0.3rem 0 0.3rem 1.25rem;

    position: relative;
    color: var(--white-color);
    background-color: rgba(0, 0, 0, 0.336);
    transition: var(--time);
}



.nav-link:hover {
    color: var(--white-color);
    background-color: rgba(0, 0, 0, 0.1);
}

.active {
    color: var(--white-color);
    background-color: rgba(0, 0, 0, 0.1);
}

.active::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 46px;
    width: 3px;
    background-color: var(--white-color);
    transition: var(--time);
}

.show-side {
    left: 0;
}

.space-toggle {
    padding-left: calc(var(--nav-width) + 1rem);
}

.cont {
    margin: 1rem 2rem 1rem 2rem;
}



@media screen and (min-width: 768px) {
    main {
        margin: calc(var(--header-height)) 0 0 0;
        padding: 0 0 0 calc(var(--nav-width));
    }

    .header {
        height: calc(var(--header-height));
        padding-left: var(--nav-width);
    }

    .sidebar {
        left: 0;
        padding: 1rem 0 0 0;
    }

    .show-side {
        width: calc(var(--header-height) + 180px);
    }

    .space-toggle {
        padding-left: calc(var(--nav-width) + 134px);
        padding-right: 0px;
    }


}





@media screen and (max-width: 768px) {
    .img-max {
        height: 62px;
        width: 62px;
        margin-left: 0px;
    }

    .show-title {
        display: none;
        transition: var(--time);
    }

    .header {
        margin-left: 0;
    }

    .header.space-toggle {
        padding-left: var(--nav-width);
    }

    .space-toggle {
        padding-left: calc(var(--nav-width) - 1rem);
        padding-right: 0;
    }

    .nav-logo {
        padding-left: 0.35rem;
    }

    .show-side {
        width: 74px;
    }

    .nav-logo-icon {
        margin-left: 0.1rem;
    }

    .cont {
        margin: 2rem 1rem 2rem 1rem;
    }

}