:root {
    --uni-color: #00385E;
    --bg-gray: #FAFAFA; /*F5F5F5*/
    --bg-cyan: #0DCAF0;
    --bg-cyan-selected: #0dcaf0b5;
    --bg-dark-gray: #B1B1B1;

    --card-glassformism: #ffffffd0;
    --bg-glassformism: #00385e77;
}





label {
    color: var(--uni-color);
}

a {
    text-decoration: none;
}

input, button, img, select, textarea, .card, .btn-card {
    border-radius: 0px !important;
}

.navbar, .card {
    position: inherit;
}

.bg-gray {
    background-color: var(--bg-gray);
}

.bg-buap {
    background-color: var(--uni-color);
}

.text-uni {
    color: var(--uni-color);
}

.menu-size {
    height: 100%;
}

.text-button {
    color: white;
    font-weight: bold;
}

.text-action-button {
    font-size: 0.8rem;
}

.btn-info:hover {
    background: var( --bg-cyan-selected); /*#00bceb*/
    color: white;
}

.link-decoration {
    text-decoration: none;
    color: var(--uni-color);
}



.bread-crumb-item {
    text-decoration: none;
    color: var(--uni-color);
    font-size: 17px;
}

.nav-breadcrumb {
    background-color: var(--bg-gray);

    padding-top: 12px;
    padding-bottom: 1px;
    padding-left: 10px;
    font-size: 15px;

    color: var(--uni-color);

    --bs-breadcrumb-divider: '>';
}




/*file input*/
input[type="file"].input-file {
    color: transparent;

    width: 100%;
    height: 38px;
}

/*Button file input*/
input[type=file].input-file::file-selector-button {
    background: var(--bg-cyan);
    color: white;
    font-weight: bold;
    
    display: flexbox;
    border: none;
    width: 100%;
    height: 100%;
}

/*Button file input*/
input[type=file].input-file::-ms-browse {
    background: var(--bg-cyan);
    color: white;
    font-weight: bold;

    display: flexbox;
    border: none;
    width: 100%;
    height: 100%;
}

/*Button file input*/
input[type=file].input-file::-webkit-file-upload-button {
    background: var(--bg-cyan);
    color: white;
    font-weight: bold;
    
    display: flexbox;
    border: none;
    width: 100%;
    height: 100%;
}

/*El cursor esta dentro del button file input*/
input[type=file]::-ms-browse:hover {
    background: var(--bg-cyan-selected);
    transition: all 0.2s ease-out;
}

/*El cursor esta dentro del button file input*/
input[type=file]::file-selector-button:hover {
    background: var(--bg-cyan-selected);
    transition: all 0.2s ease-out;
}

/*El cursor esta dentro del button file input*/
input[type=file]::-webkit-file-upload-button:hover {
    background: var(--bg-cyan-selected);
    transition: all 0.2s ease-out;
}



/*Checkbox configuracion de estilos*/
input[type=checkbox].border-check {
    border-radius: unset;
    background: var(--bg-dark-gray);

    width: 20px;
    height: 20px;

    margin-right: 10px;
    border: none;
}

/*Checkbox cuando se ha checado*/
input[type=checkbox].border-check:checked {
    background: var(--bg-cyan);
}


/*Button card son botones de gran dimension utilizados para elegir opciones
en la pagina de ayuda y occiones de agregacion*/
.btn-card {
    color: white;
    background: var( --bg-cyan); /*#07c2e7*/

    text-align: center;
    font-size: 20px;
    padding: 60px 20px;

    height: 100%;
    width: 100%;
}

.btn-card:hover {
    color: white;
    background: var( --bg-cyan-selected); /*#07c2e7cc*/
}


/*Estilos para los componentes con imagenes*/
.img-margin {
    height: 400px;
    width: 100%;
}

.img-border {
    border: 2px solid black;
}

.img-user-size {
    object-fit: cover;
    aspect-ratio: 1;
}

.img-logo-size {
    height: 250px;
    width: 250px;
}


/*Estilos de los componentes para las paginas de login, restore password y logout*/
.login-card {
    width: 400px;
    height: 100%;
}

.login-background {
    background-color: var(--card-glassformism);
}

.login-card-center {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.login-button-center {
    text-align: center;
    text-decoration: none;
}

.login-button-decoration {
    text-decoration: none;
    color: var(--uni-color);
}

.bg-login {
    background-image: 
    linear-gradient( 
        var(--bg-glassformism),
        var(--bg-glassformism)
    ) , url("../assets/img/bg.png") ;

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}




/*Estilos para las tablas de visualizacion de cursos y usuarios*/
.scroll-table {
    overflow-x: scroll;
    scroll-behavior: smooth;
    width: 100%;
}

/*
.size-table-md {
    min-width: 200vh;
}
*/

/* Estilos para la barra de búsqueda */
.searchbar.admin-searchbar {
    --bs-navbar-brand-font-size: 1rem;
}

/* Estilos para la barra de notificaciones */
.notification-bar {
    position: fixed;
    z-index: 1337;
    width: 100%;
    padding-inline: 10%;
    bottom: 20px;
}

.notification-bar > div {
    box-shadow: 0 10px 25px 0px rgba(0, 0, 0, 0.27);
    border-radius: 5px;
}

@media all and (max-width: 767px) {
    .notification-bar {
        padding-inline: 0%;
        box-shadow: none;
        bottom: 0px;
    }

    .notification-bar > div {
        box-shadow: none;
    }
}

/* Estilos para el modal */
.modal-title {
    font-size: 1.25rem;
}

.modal-body {
    font-size: 1.15rem;
}

/* Estilos para el botón info xDD */
.btn-info {
    background: #00B5E2;
    color: white;
}

.btn-info:hover {
    background: #00bceb;
    color: white;
}

.btn-info:focus {
    background: #00bfef;
    color: white;
}

.btn-info:active {
    background: #00ccff;
    color: white;
}

.btn-info::before {
    background: #00bfef;
    color: white;
}

/* Estilos para el botón de 'blue' */
.btn-blue, .btn-primary {
    background: var(--uni-color);
    color: white;
}

.btn-blue:hover, .btn-primary:hover {
    background: #0562a0;
    color: white;
}

.btn-blue:focus, .btn-primary:focus {
    background: #04609d;
    color: white;
}

.btn-blue:active, .btn-primary:active {
    background: #0576c2;
    color: white;
}

.btn-blue::before, .btn-primary::before {
    background: #045992;
    color: white;
}

/* Estilos para imagenes */
.img-max-width {
    max-width: 1280px;
}

/* Estilos de action button */
.max-width-action-button-group {
    display: block;
    text-align: center;
}

.style-action-button {
    min-width: 110px;
    max-width: 220px;
}

.style-action-button-div + .style-action-button-div {
    margin-top: 5px;
}

.wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.accordion {
    width: 100%;
}

.item {
    /*background: var(--bg-gray);*/
    margin-bottom: 0.6rem;
    cursor: pointer;
}

.title {
    color: rgb(43, 41, 41);
    /*background: var(--white-color);*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.8s;
}

.title:hover {
    background: var(--light-gray-color);
    padding: 0.3rem 0.5rem 0.3rem 1rem;
    transition: 0.8s;
}

.content {
    color: var(--uni-color);
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1);
}

.content.show {
    height: auto;
    max-height: 200rem;
    transition: all 0.5s cubic-bezier(1,0,1,0);
}

/* Estilo de videos */

video.page-video {
    width: 100%;
    height: auto;
}

/* nothing... literalmente */

div.nothing {
    background-color: var(--uni-color);
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: white;
    font-size: 1.2em;
}

div.nothing img {
    margin: 1em;
    width: 100%;
    max-width: 250px;
}